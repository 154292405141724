'use client';

import { useContext } from 'react';
import { EmptyStateSidebarTabs } from '../../../../lib/sidebar';
import { MeContext } from '../../../../providers/Me';
import { redirect } from 'next/navigation';
import EmptyState from '../../../../components/EmptyState';

export default function NotFoundPage() {
  const { isAuthenticated } = useContext(MeContext);

  return isAuthenticated ? (
    <EmptyState path={EmptyStateSidebarTabs.NOT_FOUND} />
  ) : (
    redirect('/login')
  );
}
