import type { ApolloQueryResult, OperationVariables } from '@apollo/client';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect } from 'react';
import type {
  Maybe,
  CurrentOrgFieldsFragment,
  CurrentUserFieldsFragment
} from '@/__generated__/graphql';
import { useGetMeQuery } from '@/__generated__/graphql';

import { ApiContext } from './Api';
import { useSession } from '@descope/nextjs-sdk/client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

interface MeContextValues {
  currentUser?: Maybe<CurrentUserFieldsFragment>;
  currentOrg?: Maybe<CurrentOrgFieldsFragment>;
  loading: boolean;
  error?: Error;
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<object>>;
  /**
   * Whether the user is successfully authenticated with Descope. This doesn't mean
   * they are in a) in an org, b) in the correct org. This only allows them to see
   * the "home" view (including registration).
   */
  isAuthenticated: boolean;
  /**
   * True if the user is logged into Descope, and is on the correct tenant.
   */
  isAuthorized: boolean;
}
export const MeContext = createContext<MeContextValues>({
  loading: false,
  isAuthenticated: false,
  isAuthorized: false
});

export function HomeMeProvider({ children }: { children: ReactNode }) {
  const { isSessionLoading, isAuthenticated } = useSession();

  return (
    <MeContext.Provider
      value={{
        loading: isSessionLoading,
        isAuthenticated,
        isAuthorized: false
      }}
    >
      {children}
    </MeContext.Provider>
  );
}

export function TenantMeProvider({ children }: { children: ReactNode }) {
  const { isSessionLoading, isAuthenticated } = useSession();
  const { loading, error, data, refetch } = useGetMeQuery({
    variables: {}
  });

  const { httpClient } = useContext(ApiContext);

  useEffect(() => {
    if (!loading && data?.me?.currentOrg) {
      httpClient?.setCurrentOrg(data.me.currentOrg);
    }
    if (!loading && data?.me?.currentUser) {
      const { _id: id, email, displayName } = data.me.currentUser;
      const ddUser = { id, email, name: displayName ?? undefined };
      datadogRum.setUser(ddUser);
      datadogLogs.setUser(ddUser);
    }
    return () => {
      datadogLogs.clearUser();
    };
  }, [loading, data, httpClient]);

  const isAuthorized =
    isAuthenticated && !!data?.me?.currentUser && !!data?.me?.currentOrg;

  return (
    <MeContext.Provider
      value={{
        loading: loading || isSessionLoading,
        error,
        currentOrg: data?.me?.currentOrg,
        currentUser: data?.me?.currentUser,
        refetch,
        isAuthenticated,
        isAuthorized
      }}
    >
      {children}
    </MeContext.Provider>
  );
}
